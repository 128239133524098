@font-face {
  font-family: Mehr Nastaliq;
  src: url("https://5.cdn.tazkia.org/assets/MehrNastaliq1beta.ttf") format("truetype");
  font-weight: 400;
  font-style: normal
}

.jsoneditor-mode-code {
  height: 37rem !important;
  /* font-family: 'Jameel Noori Nastaleeq', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* font-family: 'Courier New', Courier, monospace; */
}

/* .jsoneditor-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
} */

.jsoneditor-string, .jsoneditor-text {
  font-family: 'Noto Sans', 'Jameel Noori Nastaleeq', 'Mehr Nastaliq', 'Noto Nastaliq Urdu Draft', sans-serif !important;
}

.ace_content, .ace-string, .jsoneditor-mode-code {
  /* font-family: monospace !important; */
}

.jsoneditor-string {
  font-size: 1rem !important;
  line-height: 1rem !important;
}

.App {
  text-align: center;
  color: black;
}

.TabPanelBody {
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}